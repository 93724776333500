import * as Sentry from "@sentry/vue";

const getUrl = (type) => {
    if (type === "strapiBo") {
        return import.meta.env.VITE_STRAPI_PATH_BO;
    } else if (type === "strapi") {
        return import.meta.env.VITE_STRAPI_PATH;
    } else if (type === "platform") {
        return import.meta.env.VITE_PLATFORM_PATH;
    } else if (type === "ourAssets") {
        return import.meta.env.VITE_DIGITAL_OCEAN_SPACE_PATH;
    }
};

const internalServicesUrl = (originalDomain) => {
    // For int.amoncasino.com for our internal services like 2FA, we don't have a different url for local and stage so we are always proxing the prod url.
    return `${import.meta.env.VITE_INTERNAL_SERVICES_PATH}${originalDomain.replace("www.", "")}`;
};

// Normal Images that on Dev come from Strapi and stage/prod come from DIgitalOcean CDN
// For content that on Strapi has Mobile and Desktop images
const promoOrBannerImageUrl = (item, mobileImage, desktopImage) => {
    if (item) {
        let device = "desktopImage";
        if (mobileImage) {
            device = "mobileImage";
        } else if (desktopImage === null || desktopImage === false || desktopImage === undefined) {
            if (item.mobileImage && window.innerWidth < 576) {
                device = "mobileImage";
            }
        }

        if ((import.meta.env.VITE_ENV === "dev" || import.meta.env.VITE_ENV === "devConnectedToProd") && item[device].data) {
            // In local we are getting the images directly from strapi
            return `${getUrl("strapi")}${item[device].data.attributes.url}`;
        } else if (item[device].data) {
            // Stage and Prod we are getting the images from DigitalOcean Spaces CDN
            return item[device].data.attributes.url;
        }
        return null;
    }
};

// Normal Images that on Dev come from Strapi and stage/prod come from DIgitalOcean CDN
// For content that has only one image for both desktop and mobile
const getImageUrl = (item) => {
    if (item) {
        if ((import.meta.env.VITE_ENV === "dev" || import.meta.env.VITE_ENV === "devConnectedToProd") && item.image && item.image.data) {
            // In local we are getting the images directly from strapi
            return `${getUrl("strapi")}${item.image.data.attributes.url}`;
        } else if (item.image && item.image.data) {
            // Stage and Prod we are getting the images from DigitalOcean Spaces CDN
            return item.image.data.attributes.url;
        }
        return null;
    }
};

const getVideoUrl = (skin, videoName) => {
    let device = "desktop";
    if (window.innerWidth < 600) {
        device = "mobile";
    }
    return `${getUrl("ourAssets")}/ourAssets/${skin}/${device}${videoName}`;
};

const arrayContains = (array, item) => {
    return array.indexOf(item) > -1;
};

const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

const createCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toGMTString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
};

const eraseCookie = (name) => {
    createCookie(name, "", -1);
};

const setModalVisibility = (newValue, router) => {
    if (newValue) {
        $("body").addClass("overflow-hidden");
        $(".black-backdrop").addClass("visible");
        $(".my-modal").addClass("visible");
        $(".my-modal").removeClass("notVisible");
    } else {
        $("body").removeClass("overflow-hidden");
        $(".black-backdrop").removeClass("visible");
        $(".my-modal").removeClass("visible");
        $(".my-modal").addClass("notVisible");
        if (router) {
            router.push("/");
        }
    }
};

const setSideMenuVisibility = (toggle) => {
    if (toggle) {
        $("body").toggleClass("overflow-hidden");
        $(".side-menu-component").toggleClass("open");
        $(".black-backdrop").toggleClass("visible");
        $("#side-menu-toggle-button").toggleClass("x-form");
    } else {
        $("body").removeClass("overflow-hidden");
        $(".side-menu-component").removeClass("open");
        $(".black-backdrop").removeClass("visible");
        $("#side-menu-toggle-button").removeClass("x-form");
    }
};

const getPageBySlug = (slug, pages) => {
    // slug passed should always be without local
    let websiteRoute = null;
    Object.keys(pages).forEach((pageKey) => {
        if (pages[pageKey].slug.indexOf(slug) !== -1) {
            websiteRoute = `/pages/${pages[pageKey].websiteRoute}`;
        }
    });
    return websiteRoute;
};

const getPromotionBySlug = (slug, promotions) => {
    // slug passed should always be without local
    let websiteRoute = null;
    promotions.promotions.some((promotion) => {
        if (promotion.attributes.slug.indexOf(slug) !== -1) {
            websiteRoute = `/promotions/${promotion.attributes.websiteRoute}`;
            return true;
        }
    });
    return websiteRoute;
};

const debounce = (func, time) => {
    let timer;
    return function () {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(func, time);
    };
};

const cleanDataRegex = (value) => {
    if (typeof value === "undefined" || value === null || value === "") {
        return true;
    }
    //No Emojis, No Currency Symbols, No Country Flags and no extra characters
    let testResult = /\p{Extended_Pictographic}|[$Դ@៛¥₡£€¢₣₹﷼₪₱ł฿₺₴₫!#|:£$?;%~^&*()\{\}\[\]<>🇦&🇧&🇨&🇩&🇪&🇫&🇬&🇭&🇮&🇯&🇰&🇱&🇲&🇳&🇴&🇵&🇶&🇷&🇸&🇹&🇺&🇻&🇼&🇽&🇾&🇿]/u.test(value);
    return !testResult;
};

const formatDateTime = (date) => {
    date = new Date(date);
    return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
};

const formatTime = (date) => {
    date = new Date(date);
    return `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
};

let scriptRetryCount = 0;
const loadAnyScript = (url, id, callback) => {
    const element = document.getElementById(id);
    if (element !== null) {
        // Script Already Exist
        callback();
    } else {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.id = id;
        script.src = url;
        if (script.readyState) {
            // Only required for IE <9
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            // Others
            script.onload = function () {
                callback();
            };
            script.onerror = function () {
                console.log(`loadAnyScript Error - Id: ${id} `);
                if (scriptRetryCount < 3) {
                    const element = document.getElementById(id);
                    if (element !== null) {
                        scriptRetryCount = scriptRetryCount + 1;
                        element.parentNode.removeChild(element);
                        loadAnyScript(url, id, callback);
                    }
                } else {
                    scriptRetryCount = 0;
                    setTimeout(() => {
                        Sentry.withScope(function (scope) {
                            scope.setLevel("error");
                            Sentry.setExtra("Url", url);
                            Sentry.setExtra("Id", id);
                            scriptRetryCount > 0 ? Sentry.setExtra("styleScript Retry Count", scriptRetryCount) : null;
                            Sentry.captureMessage("OnError of loadAnyScript method");
                        });
                    }, 5000);
                }
            };
        }
        document.getElementsByTagName("body")[0].appendChild(script);
    }
};

const deviceInfo = () => {
    let isMobile = false;
    if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4),
        )
    ) {
        isMobile = true;
    }

    let os = "desktop";
    if (navigator.userAgent.match(/Android/i)) {
        os = "android";
    } else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
        os = "ios";
    }

    return { isMobile, os };
};

const findGame = (allGamesArray, gameId, gameSlug) => {
    let game = null;
    allGamesArray.some((gameObject) => {
        if (gameObject.gameId === gameId || gameObject.slug === gameSlug) {
            if (gameObject.enabled) {
                game = gameObject;
            }
            return true;
        }
    });
    return game;
};

const currencyCodeToSymbol = {
    USD: "$",
    EUR: "€",
    BRL: "R$",
    PLN: "zł",
    CAD: "C$",
    NOK: "kr",
    GBP: "£",
    NZD: "NZ$",
    AUD: "A$",
    JPY: "¥",
    TRY: "₺",
};

const countryCurrencyCode = [
    { code: "us, ar, bo, cl, co, cr, cu, do, ec, sv, gt, hn, mx, ni, pa, py, pe, pr, uy, ve", currencyCode: "USD", conversion: 1 },
    { code: "en, fr, fi", currencyCode: "EUR", conversion: 1 },
    { code: "ca", currencyCode: "CAD", conversion: 1.5 },
    { code: "gb", currencyCode: "GBP", conversion: 1 },
    { code: "pl", currencyCode: "PLN", conversion: 5 },
    { code: "nz", currencyCode: "NZD", conversion: 1.5 },
    { code: "au", currencyCode: "AUD", conversion: 1.5 },
    { code: "jp", currencyCode: "JPY", conversion: 120 },
    { code: "tr", currencyCode: "TRY", conversion: 15 },
    { code: "no", currencyCode: "NOK", conversion: 10 },
    { code: "br", currencyCode: "BRL", conversion: 5 },
];

const getSymbolFromCurrencyCode = (currencyCode) => {
    if(currencyCodeToSymbol[currencyCode] === undefined){
        return "---"
    }
    return currencyCodeToSymbol[currencyCode];
};

const localizeAmount = (contentAmountConversion, currencyCode, currencySymbol) => {
    if (getCookie("cmsLanguageCode") !== null) {
        // Some browsers may not support this so we wrap it in a try catch
        try {
            let result = new Intl.NumberFormat(getCookie("cmsLanguageCode"), { style: "currency", currency: currencyCode }).format(contentAmountConversion).replace(/\D00(?=\D*$)/, "");
            return result;
        } catch (e) {
            return `${contentAmountConversion} ${currencySymbol}`;
        }
    } else {
        return `${contentAmountConversion} ${currencySymbol}`;
    }
};

const contentCurrencyConversion = (text, myStore, callsStore) => {
    // Get Amount and Currency
    const startIndex = text.indexOf("[[");
    const endIndex = text.indexOf("]]");
    const contentAmount = text.slice(startIndex + 2, endIndex - 1);
    const contentCurrency = text.slice(endIndex - 1, endIndex);

    // Get Currency and Location
    let location = callsStore.locationObject ? callsStore.locationObject.country.code.toLowerCase() : "gb";
    let currencyCode;
    if (myStore.loggedIn) {
        currencyCode = callsStore.userCurrencyCode;
    } else {
        countryCurrencyCode.some((ccc) => {
            if (ccc.code.indexOf(location) !== -1) {
                currencyCode = ccc.currencyCode;
                return true;
            } else {
                currencyCode = "EUR";
            }
        });
    }

    //Do Currency Conversion
    let contentAmountConversion;
    countryCurrencyCode.some((ccc) => {
        if (ccc.currencyCode.indexOf(currencyCode) !== -1) {
            contentAmountConversion = contentAmount.replace(",", "").replace(" ", "");
            contentAmountConversion = (parseFloat(contentAmountConversion) * ccc.conversion).toFixed(2).replace(".00", "");
            return true;
        } else {
            contentAmountConversion = parseFloat(contentAmount).toFixed(2).replace(".00", "");
        }
    });

    //Do Geo Location Rules
    let newText = text.replace(`[[${contentAmount}${contentCurrency}]]`, localizeAmount(contentAmountConversion, currencyCode, currencyCodeToSymbol[currencyCode]));
    if (newText && newText.indexOf("[[") !== -1 && newText.indexOf("€]]") !== -1) {
        return contentCurrencyConversion(newText, myStore, callsStore);
    } else {
        return newText;
    }
};

export { getUrl, internalServicesUrl, arrayContains, getCookie, createCookie, eraseCookie, setModalVisibility, setSideMenuVisibility, getPageBySlug, getPromotionBySlug, debounce, cleanDataRegex, promoOrBannerImageUrl, getVideoUrl, formatDateTime, formatTime, loadAnyScript, deviceInfo, findGame, getImageUrl, getSymbolFromCurrencyCode, contentCurrencyConversion };
