import { defineStore } from "pinia";
import { arrayContains } from "../helpers/helpers";

const skins = {
    ac: {
        apgSeal: "https://54c0746c-8ca8-4194-bf29-c52cbdbd7948.snippet.antillephone.com/apg-seal.js",
        xcmSeal: "https://783f4b52-a786-4e52-b3c5-1c7714021aed.seals-xcm.certria.com/xcm-seal.js",
        anjSeal: "",
        companyName: "Purple Bay B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "157944(0)",
        registrationAddress: "Korporaalweg 10, Willemstad, Curaçao",
        regulatedBy: "Antillephone N.V.",
        licenceNumber: "8048/JAZ2021-169",
        originalDomain: "www.amoncasino.com",
        originalName: "Amon Casino",
        coolName: "Amon",
        complaintsEmail: "complaints@amoncasino.com",
        privacyEmail: "dataprivacy@amoncasino.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 12,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "zpo2favh", supportEmail: "support@amoncasino.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "ob5urvu1", supportEmail: "serviceclient@amoncasino.com" },
            // { code: "br", strapiCode: "pt-BR", language: "Português", defaultChatButtonId: "j9f78zwv", supportEmail: "support@amoncasino.com" },
            { code: "pl", strapiCode: "pl", language: "Polski", defaultChatButtonId: "bxug26d9", supportEmail: "obslugaklienta@amoncasino.com" },
            // { code: "fi", strapiCode: "fi", language: "Suomi", defaultChatButtonId: "lesqjw8z", supportEmail: "asiakastuki@amoncasino.com" },
            { code: "no", strapiCode: "nn-NO", language: "Norsk", defaultChatButtonId: "zmey5agw", supportEmail: "kundeservice@amoncasino.com" },
        ],
        // If we have a defaultCurrency, all users from that country will be registered with that defaultCurrency - ELSE EUR
        countries: [
            { code: "ca", defaultCurrency: "CAD" },
            // { code: "br", defaultCurrency: "BRL" },
            { code: "no", defaultCurrency: "NOK" },
            { code: "pl", defaultCurrency: "PLN" },
            // { code: "gb", defaultCurrency: "GBP" },
        ],
        pixGamingScript: "pix_amoncasino",
        hotjarSkinId: "3462093",
        welcomeOfferVideo: "", //WelcomeOfferVideo.mp4
        welcomeOffer: "Bottom", //RegisterForm, Left, Center, Bottom
        homeLobbyStyle: "netflix", // netflix, simple
        // vipPage: "pathView", // pathView, columnView
        headerType: "button", //button, icon
        modal: "middle", // middle, right
        gameThumbnailGrid: "normal", // small, normal
        gameThumbnailSize: "500-700", // 280-280, 500-700, 512-288
        thumbnailDetails: "inside", // inside, outside
        home: "one", // one, two, splash
        paymentsLayout: "row", // row, tile, rowTwo
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [
            { svg: "fa-1", title: "", description: "", titleKey: "AMON_SELLING_1", descriptionKey: "AMON_DESCRIPTION_1" },
            { svg: "fa-2", title: "", description: "", titleKey: "AMON_SELLING_2", descriptionKey: "AMON_DESCRIPTION_2" },
            { svg: "fa-3", title: "", description: "", titleKey: "AMON_SELLING_3", descriptionKey: "AMON_DESCRIPTION_3" },
            { svg: "fa-4", title: "", description: "", titleKey: "AMON_SELLING_4", descriptionKey: "AMON_DESCRIPTION_4" },
        ],
        supportPointsBlocks: [],
        liveAgentEvent: "LiveAgentInitialisation",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        // QUICKSPIN
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "WALLET_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsBelow", // noDetails, detailsBelow, detailsRight
        formStyle: "old", // old, modern
        bannerRotator: "coverflow", // coverflow, slide
        wheelOfFortune: null,
    },
    ra: {
        apgSeal: "https://98e90cdd-bc15-4f9e-bb26-bd5fef798784.snippet.antillephone.com/apg-seal.js",
        xcmSeal: "https://381fa462-2f3b-420b-8561-4e1011be55d8.seals-xcm.certria.com/xcm-seal.js",
        anjSeal: "",
        companyName: "Purple Bay B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "157944(0)",
        registrationAddress: "Korporaalweg 10, Willemstad, Curaçao",
        regulatedBy: "Antillephone N.V.",
        licenceNumber: "8048/JAZ2021-169",
        originalDomain: "www.racasino.com",
        originalName: "Ra Casino",
        coolName: "Ra",
        complaintsEmail: "complaints@racasino.com",
        privacyEmail: "dataprivacy@racasino.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 6,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "053hftk4", supportEmail: "support@racasino.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "ln99pmwz", supportEmail: "serviceclient@racasino.com" },
            { code: "no", strapiCode: "nn-NO", language: "Norsk", defaultChatButtonId: "b34sdtah", supportEmail: "kundeservice@racasino.com" },
            { code: "fi", strapiCode: "fi", language: "Suomi", defaultChatButtonId: "jxe1x16g", supportEmail: "asiakastuki@racasino.com" },
        ],
        countries: [
            { code: "ca", defaultCurrency: "CAD" },
            { code: "no", defaultCurrency: "NOK" },
            { code: "pl", defaultCurrency: "PLN" },
        ],
        // pixGamingScript: "pix_racasino",
        hotjarSkinId: "3694857",
        welcomeOfferVideo: "WelcomeOfferVideo.mp4",
        welcomeOffer: "Center",
        homeLobbyStyle: "simple",
        // vipPage: "pathView",
        headerType: "icon",
        modal: "right",
        gameThumbnailGrid: "small",
        gameThumbnailSize: "500-700",
        thumbnailDetails: "inside",
        home: "one",
        paymentsLayout: "rowTwo",
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        liveAgentEvent: "LiveAgentInitialisation",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "WALLET_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "slide",
        wheelOfFortune: null,
    },
    bo: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "https://d2644bed-8b7e-48cf-9609-e7301e31f096.snippet.anjouangaming.org/anj-seal.js",
        companyName: "Elite Cyber Services Limited",
        licenceCountry: "Belize",
        registrationNumber: "177058",
        registrationAddress: "123 Barrack Road, Belize City, Belize",
        regulatedBy: "Union Of Comoros",
        licenceNumber: "ALSI-012401013-F12",
        originalDomain: "www.bofcasino.com",
        originalName: "Bof Casino",
        coolName: "Bof",
        complaintsEmail: "complaints@bofcasino.com",
        privacyEmail: "dataprivacy@bofcasino.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 6,
        languages: [{ code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "2labgla5", supportEmail: "support@bofcasino.com" }],
        countries: [
            { code: "gb", defaultCurrency: "GBP" },
        ],
        hotjarSkinId: "3815736",
        welcomeOfferVideo: "",
        // welcomeOffer: "RegisterForm",
        homeLobbyStyle: "simple",
        // vipPage: "pathView",
        // headerType: "button",
        modal: "middle",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "512-288",
        thumbnailDetails: "outside",
        home: "two",
        paymentsLayout: "tile",
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [
            { svg: "fa-1", title: "Receive 100% up to [[100€]]", description: "on your first deposit", titleKey: "", descriptionKey: "" },
            { svg: "fa-2", title: "Receive 50% up to [[200€]]", description: "on your 2nd deposit", titleKey: "", descriptionKey: "" },
            { svg: "fa-3", title: "Receive 100% up to [[200€]]", description: "on you 3rd deposit", titleKey: "", descriptionKey: "" },
        ],
        supportPointsBlocks: [
            { svg: "fa-solid fa-clock-rotate-left", title: "24/7 support", description: "Round the clock support via email and Live Chat", titleKey: "", descriptionKey: "" },
            { svg: "fa-solid fa-game-console-handheld", title: "1700+ games", description: "From a large variety of providers", titleKey: "", descriptionKey: "" },
            { svg: "fa-solid fa-messages-question", title: "For any questions", description: "We assist with payment issues, registrations and more", titleKey: "", descriptionKey: "" },
        ],
        liveAgentEvent: "LiveAgentInitialisationBO",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN", "NOVOMATIC"],
        paymentProviders: ["CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "WALLET_CITIZEN"],
        affiliateProgram: "https://affiliates.cybereliteaffiliates.com/signup.php",
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "coverflow",
        wheelOfFortune: null,
    },
    zu: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "ILP Trading B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "164645",
        registrationAddress: "Schottegatweg Oost 10, Unit 1 - 9 Bon Bini Business Center, Willemstad, Curaçao",
        regulatedBy: "Curacao eGaming",
        licenceNumber: "1668/JAZ",
        originalDomain: "www.zumibet.com",
        originalName: "Zumi Bet",
        coolName: "Zumi",
        complaintsEmail: "complaints@zumibet.com",
        privacyEmail: "dataprivacy@zumibet.com",
        autoExclusionOptions: ["P3M", "P5Y"],
        homeLobbyGames: 6,
        languages: [{ code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "053hftk4", supportEmail: "support@zumibet.com" }],
        countries: [
            { code: "gb", defaultCurrency: "GBP" },
        ],
        // hotjarSkinId: "3462093",
        welcomeOfferVideo: "",
        welcomeOffer: "RegisterForm",
        homeLobbyStyle: "simple",
        // vipPage: "pathView",
        headerType: "button",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "280-280",
        thumbnailDetails: "inside",
        home: "one",
        paymentsLayout: "rowTwo",
        sellingBlocksStyle: "Two",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        liveAgentEvent: "LiveAgentInitialisation",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "WALLET_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsRight",
        formStyle: "modern",
        bannerRotator: "coverflow",
        wheelOfFortune: null,
    },
    lj: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "***************",
        licenceCountry: "***************",
        registrationNumber: "***************",
        registrationAddress: "***************",
        regulatedBy: "***************",
        licenceNumber: "***************",
        originalDomain: "www.letsjackpot.com",
        originalName: "Lets Jackpot",
        coolName: "Lets Jackpot",
        complaintsEmail: "complaints@letsjackpot.com",
        privacyEmail: "dataprivacy@letsjackpot.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 12,
        languages: [{ code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "7nx9munf", supportEmail: "support@letsjackpot.com" }],
        // If we have a defaultCurrency, all users from that country will be registered with that defaultCurrency
        countries: [
            { code: "gb", defaultCurrency: "GBP" }
        ],
        // pixGamingScript: "",
        hotjarSkinId: "5071153",
        welcomeOfferVideo: "",
        welcomeOffer: "Bottom",
        homeLobbyStyle: "netflix",
        // vipPage: "pathView",
        headerType: "button",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "512-288",
        thumbnailDetails: "outside",
        home: "one",
        paymentsLayout: "row",
        sellingBlocksStyle: "Two",
        sellingPointsBlocks: [
            { svg: "fa-1", title: "", description: "", titleKey: "LJ_SELLING_1", descriptionKey: "LJ_DESCRIPTION_1" },
            { svg: "fa-2", title: "", description: "", titleKey: "LJ_SELLING_2", descriptionKey: "LJ_DESCRIPTION_2" },
            { svg: "fa-3", title: "", description: "", titleKey: "LJ_SELLING_3", descriptionKey: "LJ_DESCRIPTION_3" },
        ],
        supportPointsBlocks: [],
        liveAgentEvent: "LiveAgentInitialisationBO",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "WALLET_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "coverflow",
        wheelOfFortune: {
            wheelName: "LETS JACKPOT WHEEL",
            wheelRotationRandomizr: { min: 10, max: 40 },
            wheelPrizes: [
                {
                    id: "Y60UmaVbFg14YwSniWkq", //* Bonus ID
                    title: "The free spins Expert",
                    dictionaryAndImageKey: "FREESPINS_1_PRIZE",
                    weight: 1, // Weight, if useWeight is true, the probability is calculated by weight (weight must be an integer), so probability is invalid
                },
                {
                    id: "PwzXsb0SeUSrwIlVIirz",
                    title: "The free spins Guru",
                    dictionaryAndImageKey: "FREESPINS_2_PRIZE",
                    weight: 1,
                },
                {
                    id: "7WjCAzX97y5JNLPLRO2M",
                    title: "The free spins Master",
                    dictionaryAndImageKey: "FREESPINS_3_PRIZE",
                    weight: 1,
                },
                {
                    id: "e0FohO0PI536Wy2mQApX",
                    title: "The wagerer",
                    dictionaryAndImageKey: "WAGERING_1_PRIZE",
                    weight: 1,
                },
                {
                    id: "mtQ6gK8QDFtx7UYvhbdw",
                    title: "The wagerer Master",
                    dictionaryAndImageKey: "WAGERING_2_PRIZE",
                    weight: 1,
                },
                {
                    id: "kVbhcHd0j91ZaiqvAobB",
                    title: "The benefactor",
                    dictionaryAndImageKey: "MONEY_1_PRIZE",
                    weight: 1,
                },
                {
                    id: "4aJS1GRZpxIKLgiD6Wnh",
                    title: "The benefactor Master",
                    dictionaryAndImageKey: "MONEY_2_PRIZE",
                    weight: 1,
                },
                {
                    id: "BF5zm1nTHPzT0YXQVY4L",
                    title: "The King",
                    dictionaryAndImageKey: "JACKPOT_PRIZE",
                    weight: 1,
                },
            ],
        },
    },
    bz: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "",
        licenceCountry: "",
        registrationNumber: "",
        registrationAddress: "",
        regulatedBy: "",
        licenceNumber: "",
        originalDomain: "www.betzino.com",
        originalName: "Betzino",
        coolName: "Betzino",
        complaintsEmail: "complaints@betzino.com", // To CHeck
        privacyEmail: "dataprivacy@betzino.com", // To CHeck
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 20,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "", supportEmail: "support@betzino.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "", supportEmail: "serviceclient@betzino.com" },
            { code: "no", strapiCode: "nn-NO", language: "Norsk", defaultChatButtonId: "", supportEmail: "kundeservice@betzino.com" },
            { code: "br", strapiCode: "pt-BR", language: "Português", defaultChatButtonId: "", supportEmail: "apoiar@betzino.com" },
            { code: "pl", strapiCode: "pl", language: "Polski", defaultChatButtonId: "", supportEmail: "obslugaklienta@betzino.com" },
        ],
        countries: [
            { code: "ca", defaultCurrency: "CAD" },
            { code: "br", defaultCurrency: "BRL" },
            { code: "no", defaultCurrency: "NOK" },
            { code: "pl", defaultCurrency: "PLN" },
        ],
        // pixGamingScript: "pix_racasino",
        hotjarSkinId: "",
        welcomeOfferVideo: "",
        welcomeOffer: "Bottom",
        homeLobbyStyle: "netflix",
        // vipPage: "pathView",
        headerType: "icon",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "512-288",
        thumbnailDetails: "inside",
        home: "one",
        paymentsLayout: "row",
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        // liveAgentEvent: "LiveAgentInitialisation",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "WALLET_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "slide",
        wheelOfFortune: null,
    },
    nw: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "***************",
        licenceCountry: "***************",
        registrationNumber: "***************",
        registrationAddress: "***************",
        regulatedBy: "***************",
        licenceNumber: "***************",
        originalDomain: "www.newwwwwwwww.com",
        originalName: "Newwww",
        coolName: "New",
        complaintsEmail: "complaints@new.com",
        privacyEmail: "dataprivacy@new.com",
        autoExclusionOptions: ["P3M", "P5Y"],
        homeLobbyGames: 6,
        languages: [{ code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "053hftk4", supportEmail: "support@zumibet.com" }],
        countries: [
            { code: "gb", defaultCurrency: "GBP" },
        ],
        // hotjarSkinId: "3462093",
        welcomeOfferVideo: "",
        welcomeOffer: "RegisterForm",
        homeLobbyStyle: "simple",
        // vipPage: "pathView",
        headerType: "button",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "280-280",
        thumbnailDetails: "inside",
        home: "splash",
        paymentsLayout: "rowTwo",
        sellingBlocksStyle: "Two",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        liveAgentEvent: "LiveAgentInitialisation",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "WALLET_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsRight",
        formStyle: "modern",
        bannerRotator: "coverflow",
        wheelOfFortune: null,
    },
};

// Features
const configPerFeature = {
    isSideMenuLayout: ["bz", "ra"],
    noSideMenu: ["bo"],
    shopFeatureEnabled: ["ac", "ra"],
    showTime: ["nw", "zu"],
    sideMenuBtnInBottomMobileMenu: [],
    allowBonusesInSE: ["ra", "bo", "lj"],
    blockPPGames: ["ac", "ra", "bz"],
    integratedModalBg: ["ra", "bo", "lj"],
    twoFA: ["zu"],
    homePagePromotions: [],
};

const isFeatureEnabled = (feature, skin) => {
    return arrayContains(configPerFeature[feature], skin);
};

export const useConfigStore = defineStore("config", {
    state: () => ({
        config: null,
        registerData: null,
    }),
    actions: {
        initConfig(skin) {
            const initialConfigObject = {
                skin,
                apgSeal: skins[skin].apgSeal,
                xcmSeal: skins[skin].xcmSeal,
                anjSeal: skins[skin].anjSeal,
                originalDomain: skins[skin].originalDomain,
                originalName: skins[skin].originalName,
                coolName: skins[skin].coolName,
                complaintsEmail: skins[skin].complaintsEmail,
                privacyEmail: skins[skin].privacyEmail,
                companyName: skins[skin].companyName,
                licenceCountry: skins[skin].licenceCountry,
                registrationNumber: skins[skin].registrationNumber,
                registrationAddress: skins[skin].registrationAddress,
                regulatedBy: skins[skin].regulatedBy,
                licenceNumber: skins[skin].licenceNumber,
                autoExclusionOptions: skins[skin].autoExclusionOptions,
                homeLobbyGames: skins[skin].homeLobbyGames,
                languages: skins[skin].languages,
                countries: skins[skin].countries,
                pixGamingScript: skins[skin].pixGamingScript,
                hotjarSkinId: skins[skin].hotjarSkinId,
                welcomeOfferVideo: skins[skin].welcomeOfferVideo,
                welcomeOffer: skins[skin].welcomeOffer,
                homeLobbyStyle: skins[skin].homeLobbyStyle,
                vipPage: skins[skin].vipPage,
                modal: skins[skin].modal,
                isSideMenuLayout: isFeatureEnabled("isSideMenuLayout", skin),
                shopFeatureEnabled: isFeatureEnabled("shopFeatureEnabled", skin),
                gameThumbnailGrid: skins[skin].gameThumbnailGrid,
                gameThumbnailSize: skins[skin].gameThumbnailSize,
                thumbnailDetails: skins[skin].thumbnailDetails,
                showTime: isFeatureEnabled("showTime", skin),
                home: skins[skin].home,
                noSideMenu: isFeatureEnabled("noSideMenu", skin),
                sideMenuBtnInBottomMobileMenu: isFeatureEnabled("sideMenuBtnInBottomMobileMenu", skin),
                paymentsLayout: skins[skin].paymentsLayout,
                twoFA: isFeatureEnabled("twoFA", skin),
                sellingBlocksStyle: skins[skin].sellingBlocksStyle,
                sellingPointsBlocks: skins[skin].sellingPointsBlocks,
                supportPointsBlocks: skins[skin].supportPointsBlocks,
                liveAgentEvent: skins[skin].liveAgentEvent,
                gameFeatures: skins[skin].gameFeatures,
                gameProviders: skins[skin].gameProviders,
                paymentProviders: skins[skin].paymentProviders,
                headerType: skins[skin].headerType,
                affiliateProgram: skins[skin].affiliateProgram,
                homePagePromotions: isFeatureEnabled("homePagePromotions", skin),
                promoThumbsWithDetails: skins[skin].promoThumbsWithDetails,
                formStyle: skins[skin].formStyle,
                bannerRotator: skins[skin].bannerRotator,
                integratedModalBg: isFeatureEnabled("integratedModalBg", skin),
                blockPPGames: isFeatureEnabled("blockPPGames", skin),
                wheelOfFortune: skins[skin].wheelOfFortune,
                allowBonusesInSE: isFeatureEnabled("allowBonusesInSE", skin),
            };
            this.config = Object.assign({}, this.config, initialConfigObject);
        },
        addToConfig(newValue) {
            this.config = Object.assign({}, this.config, newValue);
        },
        setRegisterData(newValue) {
            this.registerData = newValue ? Object.assign({}, this.registerData, newValue) : null;
        },
    },
    persist: true,
});
