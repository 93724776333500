<template>
    <component :is="contentTemplate" v-if="content"></component>
    <component :is="componentLoader" v-else-if="componentPath"></component>
</template>

<script>
import { defineAsyncComponent } from "@vue/runtime-core";
import { getUrl, getCookie, contentCurrencyConversion } from "../../helpers/helpers";
import { getContentVersion } from "../../services/strapi/axiosStrapiRequests";

export default {
    name: "Dynamic",
    props: {
        content: String,
        componentPath: {
            type: String,
            default: () => null,
        },
        image: Boolean,
        folder: String,
    },
    computed: {
        contentTemplate() {
            return {
                template: `<div>${this.cleanContent(this.content)}</div>`,
                methods: {
                    getUrl(type) {
                        return getUrl(type);
                    },
                    vipPageToggleVisibleClass(elementId) {
                        let element = document.getElementById(elementId);
                        element.classList.toggle("visible");
                        $(".black-backdrop").toggleClass("visible");
                    },
                },
            };
        },
        componentLoader() {
            // Usage of lazy loading component with error handling and loading capabilities for ease of use
            if (this.image) {
                return defineAsyncComponent({
                    loader: () => import(`./svgs/${this.folder}/${this.componentPath}.svg`),
                });
            } else {
                return defineAsyncComponent({
                    loader: () => import(`./${this.folder}/${this.componentPath}.vue`),
                    onError: (error, retry, fail, attempts) => {
                        this.errorCallback(error, retry, fail, attempts);
                    },
                });
            }
        },
    },
    methods: {
        cleanContent(content) {
            // To clean the rendered content and get perfectly valid HTML
            let div = document.createElement("div");

            // Content Currency Conversion
            if (content.indexOf("[[") !== -1 && content.indexOf("€]]") !== -1) {
                content = contentCurrencyConversion(content, this.myStore, this.callsStore);
            }

            // Time to change [SkinName] and any other replacements
            let languageSupportEmail;
            this.configStore.config.languages.some((language) => {
                if (language.code === getCookie("language")) {
                    languageSupportEmail = language.supportEmail;
                    return true;
                }
            });
            content = content.split("[SkinSupportEmail]").join(languageSupportEmail);
            content = content.split("[CompanyName]").join(this.configStore.config.companyName);
            content = content.split("[LicenceCountry]").join(this.configStore.config.licenceCountry);
            content = content.split("[RegistrationNumber]").join(this.configStore.config.registrationNumber);
            content = content.split("[RegistrationAddress]").join(this.configStore.config.registrationAddress);
            content = content.split("[RegulatedBy]").join(this.configStore.config.regulatedBy);
            content = content.split("[LicenceNumber]").join(this.configStore.config.licenceNumber);
            content = content.split("[SkinName]").join(this.configStore.config.originalName);
            content = content.split("[SkinComplaintsEmail]").join(this.configStore.config.complaintsEmail);
            content = content.split("[SkinPrivacyEmail]").join(this.configStore.config.privacyEmail);
            div.innerHTML = content.split("[SkinDomain]").join(this.configStore.config.originalDomain);
            return div.innerHTML;
        },
        errorCallback(error, retry, fail, attempts) {
            // Any error is attempted for a retry for atleast 3 times with a 750ms gap
            if (attempts <= 3) {
                setTimeout(
                    function () {
                        retry();
                    },
                    attempts == 3 ? 3000 : 750,
                );
            } else {
                console.log("-----Failed to fetch dynamically imported module-----");
                // When we used to do a release we were getting a lot of errors: Failed to fetch dynamically imported module
                // This is because, if the user does not reload they will try to fetch outdated components that were replaced with the release
                // So with every release we try to change the content version from CMS, if the version does not match we do a reload.
                this.checkContentVersion(fail);
            }
        },
        checkContentVersion(fail) {
            getContentVersion(this.myStore, this.configStore.config.skin).then((latestVersion) => {
                const currentVersion = getCookie("contentVersion");
                if (currentVersion !== `${latestVersion}`) {
                    location.reload();
                } else {
                    fail();
                }
            });
        },
    },
};
</script>
