// lte = Less then or equals
// gte = Greater then or equals
// containsi = Contains, case insensitive
// in = Included in an array

const bannerItemsQuery = (skin, locale) => `query {
  bannerItems (sort: "sortPriority", filters:{brand: {containsi : "${skin}"}, startDate: {lte : "${new Date().toISOString()}"}, endDate: {gte : "${new Date().toISOString()}"}}, locale: "${locale}"){
    data{
      attributes{
        title
        type
        shortDescription
        mobileImage {
          data {
              attributes {
                url
              }
            }
        }
        desktopImage{
          data {
              attributes {
                url
              }
            }
        }
        action
        includeInCountry
        excludeInCountry
        excludeInCountryLoggedOut
        campaignId
      }
    }
  }
}`;

const promotionsQuery = (skin, locale) => `query {
        promotions (sort: "sortPriority", filters:{brand: {containsi : "${skin}"}, startDate: {lte : "${new Date().toISOString()}"}, endDate: {gte : "${new Date().toISOString()}"}}, locale: "${locale}"){
          data{
            attributes{
              title
              slug
              offer
              shortDescription
              promotionContent
              websiteRoute
              mobileImage {
                data {
                    attributes {
                      url
                    }
                  }
              }
              desktopImage{
                data {
                    attributes {
                      url
                    }
                  }
              }
              bannerSlug
              affiliateCode
              campaignId
              includeInCountry
              excludeInCountry
              excludeInCountryLoggedOut
            }
          }
        }
      }`;

const loggedOutCampaignsQuery = (skin) => `query{
  loggedOutCampaigns(filters:{brand: {containsi : "${skin}"}}){
    data{
      attributes{
        campaignId
      }
    }
  }
}`;

const vipQuery = (skin, locale) => `query {
  vips(filters:{brand: {containsi : "${skin}"}}, locale: "${locale}"){
    data{
      attributes{
        title
        levels
        description
        image{
          data{
            attributes{
              url
            }
          }
        }
        
      }
    }
  }
}`;

const shopQuery = (skin, locale, shopItemNameList) => `query {
  shops(sort: "sortPriority", filters:{brand: {containsi : "${skin}"}, slug: {in : ${shopItemNameList}}}, locale: "${locale}"){
    data{
      attributes{
        title
        slug
        category
        description
        freeSpinsGameId
      }
    }
  }
}`;

const dictionariesQuery = (locale) => `query {
    dictionaries(locale: "${locale}") {
      data {
        attributes {
          slug
          text
        }
      }
    }
  }`;

const lobbiesQuery = (skin) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "nw") {
        skin = "ra";
    }

    return `query{
  lobbies(sort: "sortPriority", filters:{lobbyId: {containsi : "${skin}"}}){
    data{
      attributes{
        lobbyId
        position
        lobbyType
      }
    }
  }
}`;
};

const pagesQuery = (skin, locale) => `query {
  pages (sort: "sortPriority", filters:{brand: {containsi : "${skin}"}}, locale: "${locale}"){
    data{
      attributes{
        title
        slug
        content
        footerLink
        websiteRoute
      }
    }
  }
}`;

const websiteBlocksQuery = (skin, locale) => `query {
  websiteBlocks (filters:{brand: {containsi : "${skin}"}}, locale: "${locale}"){
    data{
      attributes{
        title
        content
        image{
          data{
            attributes{
              url
            }
          }
        }
        rightAligned
        slug
        index
        action
      }
    }
  }
}`;

const contentVersionQuery = () => `query {
  contentVersion {
    data{
      attributes{
        version
      }
    }
  }
}`;

const paymentInfoQuery = (locale, skin) => `query {
  payments (sort: "sortPriority", filters:{brand: {containsi : "${skin}"}}, locale: "${locale}"){
    data{
      attributes{
        slug
        description
        name
      }
    }
  }
}`;

const blackListedPlayersQuery = () => `query{
  blackListedPlayers{
    data{
      attributes{
        email
        fullBlock
      }
    }
  }
}`;

export { bannerItemsQuery, promotionsQuery, dictionariesQuery, lobbiesQuery, pagesQuery, contentVersionQuery, paymentInfoQuery, loggedOutCampaignsQuery, vipQuery, shopQuery, websiteBlocksQuery, blackListedPlayersQuery };
