const locationQuery = () => `query{
    location{
      postcode
      country {
        code
        dialCode
        minimumAge
        name
      }
    }
  }`;

// const brandLocalesQuery = (skin) => `query{
//     brandLocales(brandId: "${skin}"){
//       localeCode
//       name
//       enabled
//       urlSegment
//     }
//   }`;

const brandCountriesQuery = (skin) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "nw") {
        skin = "ra";
    }

    return `query{
    brandCountries (brandId: "${skin}"){
      country{
        code
        name
        dialCode
        minimumAge
      }
    }
  }`;
};

const lobbiesQuery = (skin, id) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "nw") {
        skin = "ra";
    }

    return `query {
  lobbies (brandId:"${skin}", lobbyId: "${id}") {
        id
        name
        brandGames{
            slug
            gameId
            loginRequired
            studio
            height
            width
            name
            enabled
            tags
        }
      }
    }`;
};

const balancesQuery = (userId) => `query{
  balances(userId: "${userId}"){
    type
    amount
    currencyCode
  }
}`;

const userQuery = (userId) => `query{
  user(userId: "${userId}"){
    firstName
    lastName
    displayName
    phone
    email
    nationality
    birthDate
    address1
    address2
    postcode
    city
    country{
      name
    }
    affiliateId
    currencyCode
    acceptedTcVersion
    isEmailVerified
    kycProfile{
      verified
    }
    receiveEmail
    receivePhone
    receiveSms
    receiveSnail
    currencyCode
  }
}`;

const userExistsQuery = (skin, email, phone) => `query{
  userExists(input: {brandId: "${skin}", email: "${email}", phone: "${phone}"})
}`;

const gameSessionConnectionQuery = (queryData) => `query{
  gameSessionConnection(${queryData}){
    edges{
      node{
        createdAt
        gameLaunchInfo{
          game{
            name
          }
        }
        betAmount
        winAmount
        availableBalanceAfter
        totalRounds
        gameSessionId
      }
    }
  }
}`;

const gameRoundConnectionQuery = (totalRounds, gameSessionId, userId) => `query{
  gameRoundConnection(first:${totalRounds},userId:"${userId}",gameSessionId:"${gameSessionId}",orderBy:{direction:DESCENDING,field:createdAt}){
  	  edges{
        node{
          betAmount
          promotional
          createdAt
          finishedAt
          winAmount
          totalBalanceAfter
        }
      }
  }
}`;

const lastSucceededDepositQuery = (userId) => `query{
  paymentConnection(userId: "${userId}", last: 1, type: DEPOSIT, status: SUCCEEDED) {
    edges {
      node {
        paymentId
      }
    }
  }
}`;

const paymentConnectionQuery = (queryData) => `query{
  paymentConnection(${queryData}){
    edges{
      node{
        type
        amount
        status
        method
        createdAt
        paymentId
      }
    }
  }
}`;

const userLimitsQuery = (userId) => `query{
  userLimits (userId: "${userId}"){
    createdAt
    type
    value
    status
    progress
    period
    nextResetTime
    userLimitId
  }
}`;

const documentConnectionQuery = (userId) => `query{
  documentConnection(userId: "${userId}"){
    edges{
      node{
        documentId
        createdAt
        url
        type
        status
        reason
      }
    }
  }
}`;

const userBonusConnectionQuery = (userId) => `query{
  userBonusConnection(userId:"${userId}"){
    edges{
      node{
        bonusId
        userBonusId
        status
        unifiedStatus
        bonus{
          name
          description
        	maxBetAmount
          reward{
            __typename
            ... on RelativeBonusReward{
              capValues{
                currencyCode
                value
              }
              percentage
            }
            ... on FreespinsBonusReward{
              gameIds
              betValue {
                currencyCode
                value
              }
              amount
            }
            ... on FixedBonusReward {
              amounts{
                currencyCode
                value
              }
              type
            }
          }
          activation{
            __typename
            ... on DepositBonusActivation{
              minimumDepositAmounts{
                currencyCode
                value
              }
            }
          }
        }
        wallet{
          currentBalance
          wageringProgress
          expiresAt
        }
      }
    }
  }
}`;

const userDepositBonusesQuery = (userId) => `query{
  userDepositBonuses(userId:"${userId}"){
    bonusId
    bonusCode
    bonus{
      name
      description
      maxBetAmount
      reward{
        __typename
        ... on RelativeBonusReward{
          capValues{
            currencyCode
            value
          }
          percentage
        }
        ... on FreespinsBonusReward{
          gameIds
          betValue {
            currencyCode
            value
          }
          amount
        }
        ... on FixedBonusReward {
          amounts{
            currencyCode
            value
          }
          type
        }
      } 
      activation{
        __typename
        ... on DepositBonusActivation{
          minimumDepositAmounts{
            value
            currencyCode
          }
        }
      }
    }
  }
}`;

const paymentOptionsQuery = (userId, type, currencyCode) => `query{
  paymentOptions(type: ${type}, userId: "${userId}", currencyCode: "${currencyCode}"){
    method
    currencyCode
    defaultAmount
    gateway
    max
    min
    provider
    quickOptions
    type
    accounts{
      accountId
      label
      type
    }
  }
}`;

const userCampaignBonusesQuery = (userId) => `query{
  userCampaignBonuses (userId:"${userId}" ){
    campaign{
      campaignId
      brandId
      status
    }
  }
}`;

const userAchievementPathProgressQuery = (userId) => `query{
  userCampaignBonuses (userId:"${userId}" ){
    campaign{
      campaignId
      brandId
      status
    }
  }
}`;

const shopItemConnectionQuery = (skin) => `query{
  shopItemConnection(brandId:"${skin}"){
    edges{
      node{
        id
        name
        price{
          currencyCode
          value
        }
        status
        shopItemCategory{
          name
        }
      }
    }
  }
}`;

const liveGameStatsQuery = (skin, gameIds) => {
    if (skin === "zu") {
        skin = "lj";
    } else if (skin === "nw") {
        skin = "ra";
    }

    return `query {
  liveGameStats(brandId: "${skin}", jurisdiction: CW, gameIds: ${gameIds}) {
    gameId
    results
    players
    betLimits {
      min
      max
      currencyCode
    }
  }
}`;
};

const recentWinningsQuery = (skin, orderByField, date) => `query{
  recentWinConnection(brandId: "${skin}", first: 30, orderBy:[{direction:DESCENDING, field:${orderByField}}], minConvertedAmount: 30, createdAtFrom: "${date}"){
    edges{
      node{
        recentWinId
        user{
          displayName
        }
        amount
        convertedAmount
        game{
          name
          gameId
          studio
        }
        createdAt
      }
    }
  }
}`;

const userBoxQuery = (skin, userId) => `query{
  userBoxConnection(brandId: "${skin}", userId: "${userId}"){
    edges{
      node{
        userBoxId
        status
        box{
          boxId
          name
        }
      }
    }
  }
}`;

export {
    locationQuery,
    brandCountriesQuery,
    lobbiesQuery,
    balancesQuery,
    userQuery,
    userExistsQuery,
    gameSessionConnectionQuery,
    gameRoundConnectionQuery,
    lastSucceededDepositQuery,
    paymentConnectionQuery,
    userLimitsQuery,
    documentConnectionQuery,
    userBonusConnectionQuery,
    userDepositBonusesQuery,
    paymentOptionsQuery,
    userCampaignBonusesQuery,
    userAchievementPathProgressQuery,
    shopItemConnectionQuery,
    liveGameStatsQuery,
    recentWinningsQuery,
    userBoxQuery,
};
