import { defineStore } from "pinia";
import {} from "../helpers/helpers";

export const usePerCallsStore = defineStore("perCalls", {
    state: () => ({
        tmaLobbiesArray: [],
        strapiLobbiesArray: [],
        homeLobbyIdsArray: [],
        menuLobbyIdsArray: [],
        casinoLobbyIdsArray: [],
        liveCasinoLobbyIdsArray: [],
        countriesArray: [],
        dictionaryObject: null,
        unFilteredPromotionsList: [],
        promotionsObject: null,
        pagesObject: null,
        loggedOutCampaignsList: [],
        unFilteredBannerItemsList: [],
        bannerItemsList: [],
        vipDataObject: null,
        shopItemsArray: [],
        shopItemsDataArray: [],
        websiteBlocksDataArray: [],
        promotePromotionObject: null,
        promoteGameObject: null,
        evolutionGamesArray: [],
        lastDepositMethodSlug: null,
        userCampaignsList: [],
        userActiveBoxesArray: [],
        blackListedPlayersArray: [],
    }),
    actions: {
        setTmaLobbiesArray(newValue) {
            this.tmaLobbiesArray = [...newValue];
        },
        setStrapiLobbiesArray(newValue) {
            this.strapiLobbiesArray = [...newValue];
        },
        setHomeLobbyIdsArray(newValue) {
            this.homeLobbyIdsArray = [...newValue];
        },
        setMenuLobbyIdsArray(newValue) {
            this.menuLobbyIdsArray = [...newValue];
        },
        setCasinoLobbyIdsArray(newValue) {
            this.casinoLobbyIdsArray = [...newValue];
        },
        setLiveCasinoLobbyIdsArray(newValue) {
            this.liveCasinoLobbyIdsArray = [...newValue];
        },
        setCountriesArray(newValue) {
            this.countriesArray = [...newValue];
        },
        setDictionaryObject(newValue) {
            this.dictionaryObject = Object.assign({}, newValue);
        },
        setUnFilteredPromotionsList(newValue) {
            this.unFilteredPromotionsList = [...newValue];
        },
        setPromotionsObject(newValue) {
            this.promotionsObject = Object.assign({}, newValue);
        },
        setPagesObject(newValue) {
            this.pagesObject = Object.assign({}, newValue);
        },
        setPagesObject(newValue) {
            this.pagesObject = Object.assign({}, newValue);
        },
        setLoggedOutCampaignsList(newValue) {
            this.loggedOutCampaignsList = [...newValue];
        },
        setUnFilteredBannerItemsList(newValue) {
            this.unFilteredBannerItemsList = [...newValue];
        },
        setBannerItemsList(newValue) {
            this.bannerItemsList = [...newValue];
        },
        setVipDataObject(newValue) {
            this.vipDataObject = Object.assign({}, newValue);
        },
        setShopItemsArray(newValue) {
            this.shopItemsArray = [...newValue];
        },
        setShopItemsDataArray(newValue) {
            this.shopItemsDataArray = [...newValue];
        },
        setPromotePromotionObject(newValue) {
            this.promotePromotionObject = Object.assign({}, newValue);
        },
        setPromoteGameObject(newValue) {
            this.promoteGameObject = Object.assign({}, newValue);
        },
        setWebsiteBlocksDataArray(newValue) {
            this.websiteBlocksDataArray = [...newValue];
        },
        setEvolutionGamesArray(newValue) {
            this.evolutionGamesArray = [...newValue];
        },
        setLastDepositMethodSlug(newValue) {
            this.lastDepositMethodSlug = newValue;
        },
        setUserCampaignsList(newValue) {
            this.userCampaignsList = [...newValue];
        },
        setUserActiveBoxesArray(newValue) {
            this.userActiveBoxesArray = [...newValue];
        },
        setBlackListedPlayersArray(newValue) {
            this.blackListedPlayersArray = [...newValue];
        },
    },
    getters: {
        allGamesArray: (state) => {
            let allGamesArray;
            state.tmaLobbiesArray.some((lobby) => {
                if (lobby.id.indexOf("all-games") !== -1) {
                    allGamesArray = lobby.brandGames;
                    return true;
                }
            });
            return allGamesArray;
        },
        sortedCountriesArray: (state) => {
            return state.countriesArray.sort(function (a, b) {
                if (a.country.name < b.country.name) {
                    return -1;
                }
                if (a.country.name > b.country.name) {
                    return 1;
                }
                return 0;
            });
        },
        footerPagesObject: (state) => {
            let footerPages = {};
            Object.keys(state.pagesObject).forEach((pageKey) => {
                if (state.pagesObject[pageKey].footerLink) {
                    footerPages[pageKey] = state.pagesObject[pageKey];
                }
            });
            return footerPages;
        },
    },
    persist: true,
});
